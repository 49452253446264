import { createApp } from "vue";
import "amfe-flexible"; //移动端适配
import "vant/lib/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
const app = createApp(App);
app.use(store).use(router);
// app.mount("#app");
router.isReady().then(() => app.mount("#app"));
