import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const state = {
  oneself: {}, //个人信息
};
const mutations = {
  changeOneself(state, oneself) {
    state.oneself = oneself;
  },
};
const actions = {
  changeOneself({ commit }, rawData) {
    console.log(rawData);
    commit("changeOneself", rawData);
  },
};

export default createStore({
  state,
  mutations,
  actions,
  plugins: [createPersistedState({ storage: window.sessionStorage })],
});
