<template>
  <div>
    <router-view />
  </div>
</template>
<script>
import { mapState } from "vuex";
import { onBeforeMount } from "vue";
export default {
  name: "app",
  components: {},
  computed: {
    ...mapState({}),
  },
  setup() {
    onBeforeMount(() => {
      //  console.log(2222)
      //  useGetToken(useGetUrlParam("miniToken"));
      // 判断是不是第一次进入程序
      // const type = window.performance.getEntriesByType("navigation")[0].type;
      // if (type === "navigate" && useGetUrlParam("miniToken")) {
      //   useGetToken(useGetUrlParam("miniToken"));
      // }
    });

    return {};
  },
};
</script>
<style>
@import "./assets/scss/index.scss";
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: "PingFang-SC-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  /* background: #f8f8f8; */
  /* min-height: 100vh; */
}
</style>
