import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    path: "/",
    name: "autoLogin",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AutoLogin/index.vue"),
    meta: {
      recordPath: true,
      title: "易百汇",
    },
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  // 可以做些什么
  // 设置页面 title
  document.title = to.meta.title ? to.meta.title : "关怀";
  next();
});
export default router;
